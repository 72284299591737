@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  .material-symbols-outlined {
    font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 48;
  }

  .material-symbols-outlined-fill {
    font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 48;
  }

  .material-symbols-outlined-fill-opsz-20 {
    font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 20;
  }

  .material-symbols-outlined-fill-1 {
    font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 48;
  }

  html,
  body,
  #root {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    scroll-behavior: smooth;
    @apply font-Merriweather;
  }

  ::-webkit-scrollbar {
    display: none;
  }
}

.swiper-slide:not(.swiper-slide-active) {
  opacity: 0 !important;
}

.animation {
  animation: animate 0.5s ease-in-out;
}

@keyframes animate {
  from {
    opacity: 0;
    transform: translateY(3rem);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
