.ErrorContent {
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* gap: 4; */
}

.ErrorContent__icon {
  @apply text-secondary-dark;
  font-size: 100px;
}